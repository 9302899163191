import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NavbarComponent } from '../navbar/navbar.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { ModalPopupComponent } from './modalpopup/modalpopup.component';
import { TransactionSuccessPopupComponent } from './transactionSuccessPopup/transactionsuccess.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from './pagination/pagination.module';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { LoaderComponent } from './loader/loader.component';
import { DiscountPageComponent } from './discount-page/discount-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TwoDigitDecimalDirective } from './two-digit-decimal.directive'
import { PositiveIntegerRestrictDirective } from './numbersOnly-restrictDigit.directive'

import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { PreLoaderComponent } from './loader/preloader.component';
import { SwitchStoreComponent } from '../switch-store/switch-store.component';
import { MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';


import { ToastrModule } from 'ngx-toastr';
import { SharedRoutingModule } from './shared-routing.module';
import { UnitInfoLoaderComponent } from './loader/loader-UnitInfo.component';
import { ExistingContactComponent } from './existing-contact/existing-contact.component';
import { CommonMethodService } from './common-method.service';
import { HasPermissionDirective } from './permission/has-permission.directive';
import { PaymentComponent } from './payment/payment.component';
import { MerchandiseSharedComponent } from './merchandise/merchandise.shared.component';
import { MilitaryFormComponent } from './military-form/military-form.component';
import { AlternateContactComponent } from './alternate-contact/alternate-contact.component';
import { InvoiceSettingComponent } from './invoice-setting/invoice-setting.component';
import { InfoSheetComponent } from './info-sheet/info-sheet.component';
import { DemographicComponent } from './demographic/demographic.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { SuccessTickComponent } from './success-tick/success-tick.component';
import { FailureCrossComponent } from './failure-cross/failure-cross.component';
import { RefundComponent } from './refund/refund.component';
import { AutoPaySharedComponent } from './autoPay/autoPay.component';
import { EditRentSharedComponent } from './edit-Rent/edit-Rent.component';
import { FacOpsUnitSelectionComponent } from '../facilityOperations/facOps-prospect/facOps-prospect-details/fac-Ops-unitSelection/fac-Ops-unitSelection.component';
import { PricingPromotionSharedComponent } from './pricing-promotions/pricing-promotion.component';
import { FeesSharedComponentComponent } from './feeShared/feesChargesShared.component';
import { PropertyProtectionComponent } from './property-protection/property-protection.component';
import { AllowanceSharedComponent } from './allowance-Shared/allowanceShared.component';
import { DebounceCurrencyDirective } from './currenceyInput.directive';
import { NotesSharedComponent } from './notes-shared/notes-shared.component';
import { FollowupSharedComponent } from './followup-shared/followup-shared.component';
import { customCurrencyPipe } from './customCurrency.pipe';
import { EditFollowUpComponent } from './editFollowUp/editFollowUp-componsnt';
import { HighLightDirectives } from './highlighter.directive';
import { SmsBoxComponent } from './sms-box/sms-box.component';
import { DocumentSharedComponent } from './documents-shared/document-shared.component';
import { PhonePipe } from './phone.pipe';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { PermissionHandlerDirective } from './permissonHandler.directive';
import { MandatoryFieldsDirective } from './mandatoryFieldsHandler.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DlscannerComponent } from './DL-Scanner/dlscanner.component';
import { FingerprintpopupComponent } from './fingerprintpopup/fingerprintpopup.component';
import { UserloginComponent } from './user-login/user-login.component';
import { PortalListSharedComponent } from './portal-list-shared/portal-list-shared.component';
import { stringMaskPipe } from './portal-list-shared/string.mask.pipe';
import { CallRecordingPopupComponent } from './callrecording-popup/callrecording-popup.component';
import { SearchBarComponent } from '../top-bar/search-bar/search-bar.component';
import { StoreInfoComponent } from '../top-bar/store-info/store-info.component';
import { NotificationBarComponent } from '../top-bar/notification-bar/notification-bar.component';
import { callDurationPipe } from './duration.pipe';
import { LoaderAudioComponent } from './loader/loader-audio.component';
import { AddNotesSharedComponent } from './add-notes-shared/add-notes-shared.component';
import { BusinessAccountComponent } from './business-account/business-account.component';
import { TaxExemptComponent } from './tax-exempt/tax-exempt.component';
import { TaxGroupComponent } from './tax-group/tax-group.component';
import { DebounceClickDirective } from 'src/app/Shared/buttonDebounceClick.directive';
import { MaskPhoneNumberPipe } from './mask.pipe';
import { BackRefreshModalComponent } from './back-refresh-modal/backRefreshModal.component';
import { RestrictHtmltagcharactersDirective } from './restricthtmltagcharacters.directive';
import { FacilityLocksComponent } from './facility-locks/facility-locks.component';
import { MakePaymentComponent } from './makePayment-Shared/make-payment.component';
import { CartSharedComponent } from './cartShared/cart-shared.component';
import { CallScriptComponent } from './call-script/call-script.component';
import { HighlightCallScriptFieldsDirective } from './highlight-call-script-fields.directive';
import { DiscountListComponent } from './discount-list/discount-list.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { ProofOfInsuranceComponent } from './proof-of-insurance/proof-of-insurance.component';


@NgModule({
    declarations: [NavbarComponent, TopBarComponent, SearchBarComponent, NotificationBarComponent, StoreInfoComponent, ModalPopupComponent, TransactionSuccessPopupComponent,
        SelectTemplateComponent, LoaderComponent, DiscountPageComponent,
        HasPermissionDirective, PreLoaderComponent, UnitInfoLoaderComponent,
        ExistingContactComponent, SwitchStoreComponent, MerchandiseSharedComponent, PaymentComponent, MilitaryFormComponent,
        AlternateContactComponent, InvoiceSettingComponent, InfoSheetComponent, AutoPaySharedComponent, TwoDigitDecimaNumberDirective,
        DemographicComponent, SuccessTickComponent, FailureCrossComponent, DebounceClickDirective,
        RefundComponent, EditRentSharedComponent, FacOpsUnitSelectionComponent,
        PricingPromotionSharedComponent, FeesSharedComponentComponent, TwoDigitDecimalDirective, PositiveIntegerRestrictDirective,
        PropertyProtectionComponent, AllowanceSharedComponent, DebounceCurrencyDirective,
        NotesSharedComponent, FollowupSharedComponent, customCurrencyPipe, PhonePipe,
        EditFollowUpComponent, HighLightDirectives, SmsBoxComponent, DocumentSharedComponent,
        PermissionHandlerDirective, MandatoryFieldsDirective,
        DlscannerComponent, FingerprintpopupComponent, UserloginComponent,
        PortalListSharedComponent, stringMaskPipe, CallRecordingPopupComponent, callDurationPipe, LoaderAudioComponent, AddNotesSharedComponent, BusinessAccountComponent, TaxExemptComponent, TaxGroupComponent,
        MaskPhoneNumberPipe, BackRefreshModalComponent, RestrictHtmltagcharactersDirective, FacilityLocksComponent, MakePaymentComponent, CartSharedComponent, CallScriptComponent, HighlightCallScriptFieldsDirective, DiscountListComponent, StripePaymentComponent, ProofOfInsuranceComponent
    ],

    imports: [CommonModule, ModalModule.forRoot(), PaginationModule, MaskedTextBoxModule, DragDropModule,
        GridAllModule, ToolbarModule, FormsModule, ReactiveFormsModule, SharedRoutingModule, RichTextEditorAllModule,
        DropDownListModule, ToastModule, DatePickerModule, InfiniteScrollModule, ToastrModule.forRoot({ preventDuplicates: true }), TooltipAllModule,
        NumericTextBoxModule],

    exports: [NavbarComponent, TopBarComponent, SearchBarComponent, NotificationBarComponent, StoreInfoComponent, PaginationModule, SmsBoxComponent, TransactionSuccessPopupComponent,
        ToastModule, ModalPopupComponent, SelectTemplateComponent, LoaderComponent, ExistingContactComponent,
        DiscountPageComponent, HasPermissionDirective, DropDownListModule, DatePickerModule,
        PreLoaderComponent, UnitInfoLoaderComponent, ToastrModule, SwitchStoreComponent, MerchandiseSharedComponent, PaymentComponent,
        MilitaryFormComponent, AlternateContactComponent, InvoiceSettingComponent, InfoSheetComponent, TwoDigitDecimaNumberDirective,
        DemographicComponent, SuccessTickComponent, FailureCrossComponent, TwoDigitDecimalDirective, PositiveIntegerRestrictDirective,
        RefundComponent, AutoPaySharedComponent, EditRentSharedComponent, PhonePipe, DebounceClickDirective,
        FacOpsUnitSelectionComponent, PricingPromotionSharedComponent, DebounceCurrencyDirective,
        FeesSharedComponentComponent, PropertyProtectionComponent, AllowanceSharedComponent,
        NotesSharedComponent, BusinessAccountComponent, FollowupSharedComponent, customCurrencyPipe,
        EditFollowUpComponent, HighLightDirectives, DocumentSharedComponent,
        PermissionHandlerDirective, MandatoryFieldsDirective, HighlightCallScriptFieldsDirective,
        TooltipAllModule, DlscannerComponent, FingerprintpopupComponent, UserloginComponent,
        PortalListSharedComponent, CallRecordingPopupComponent, AddNotesSharedComponent, TaxExemptComponent, TaxGroupComponent, FacilityLocksComponent,
        stringMaskPipe, callDurationPipe, LoaderAudioComponent, MaskPhoneNumberPipe, BackRefreshModalComponent, MakePaymentComponent, CartSharedComponent, CallScriptComponent, StripePaymentComponent,
        ProofOfInsuranceComponent],
    providers: [CommonMethodService, CurrencyPipe, customCurrencyPipe, DecimalPipe],
})
export class SharedModule { }
